.search-input {
    border-radius: 50px;
    box-shadow: 0 5px 15px 0 rgba(76, 35, 181, 0.1);
    background-color: #FFFFFF;
    border: unset !important;
    width: 100% !important;

    /*text-transform: capitalize;*/

}
