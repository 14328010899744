.popover .arrow {
    display: none;
}
.popover{
    width:max-content;
}
.tooltip .arrow {
    display: none;
}
.patients-table-physician {
    padding: 10px 20px;
    font-size: 17px;
}