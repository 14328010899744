.modalBackground{
    width: 100vw;
    height: 100vh;
    margin: 0 75px 0 0;
    padding: 93px 19px 39px 20px;
    object-fit: contain;
    background-color: rgba(36, 36, 81, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 101;

}
/*.loaderBackground{*/
/*     background-color: rgba(36, 36, 81, 0.5);*/
/*     z-index: 999 !important;*/
/* }*/
.loader {
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 10px solid #5560E1;
    border-radius: 50%;
    border-top: 10px solid #242451;
    font-size: 58px;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/*@media (prefers-reduced-motion: no-preference) {
    .loader {
        animation: spin infinite 2s linear;
    }
}*/
